import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { pageContext } from "../PageProvider/PageProvider";

export function CookieScript() {
  const { locale } = useContext(pageContext);
  const isProduction = (process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV) === "production";

  return (
    <Helmet
      htmlAttributes={{
        lang: locale || "en",
      }}
    >
      <link rel="dns-prefetch" href="https://cdn.cookie-script.com" />
      {isProduction && (
        <script
          charSet="UTF-8"
          data-cs-lang={locale}
          src="https://cdn.cookie-script.com/s/b61a9316295a3a092c7d869413779980.js"
        ></script>
      )}
      {!isProduction && (
        <script
          charSet="UTF-8"
          data-cs-lang={locale}
          src="https://cdn.cookie-script.com/s/ea099aaacf3a3f5fec0a79635091f285.js"
        ></script>
      )}
    </Helmet>
  );
}
